
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Menu',
  data() {
    return {
      showAbout: false,
      showCompetency: false,
      showPr: false,
    }
  },
  methods: {
    moveOver(link: 'about' | 'competency' | 'pr') {
      switch (link) {
        case 'about':
          this.showAbout = true
          break
        case 'competency':
          this.showCompetency = true
          break
        case 'pr':
          this.showPr = true
          break
      }
      console.log(link)
    },
    moveLeave(link: 'about' | 'competency' | 'pr') {
      switch (link) {
        case 'about':
          this.showAbout = false
          break
        case 'competency':
          this.showCompetency = false
          break
        case 'pr':
          this.showPr = false
          break
      }
      console.log(link)
    },
  },
})
