<template>
  <div class="home">
    <div class="home-topic">
      <h2>Klinik – Sanierung</h2>
      <div>
        <div>
          <img class="Image" src="../assets/home/change-management.jpg" />
        </div>
        <div>
          <p>
            Unter Sanierung versteht man die Gesamtheit der Maßnahmen zur
            Beseitigung der Klinikkrise durch nachhaltig wirkende
            Restrukturierung und Stärkung des Kerngeschäfts. Die Hintergründe
            für Krankenhaussanierungen sind mannigfaltig. Sie reichen von
            organisatorischen Änderungen bis hin zu Änderungen in der
            Personalgestaltung. Hierzu haben wir ein Konzept entwickelt um ihre
            Wettbewerbsfähigkeit wieder herzustellen.
          </p>
          <p>
            Weitere Informationen und Kontaktaufnahme unter:
            <a href="mailto:mail@pmp-sc.com">mail@pmp-sc.com</a>
          </p>
        </div>
      </div>
    </div>
    <div class="home-topic">
      <h2>Klinik – Mitarbeitercoaching</h2>

      <div>
        <div>
          <img class="Image" src="../assets/home/coaching.jpg" />
        </div>
        <div>
          <p>
            Coaching wird als Sammelbegriff für unterschiedliche
            Beratungsmethoden (Einzelcoaching, Teamcoaching, Projektcoaching)
            verwendet. Die Ziele dieser Gespräche reichen von der Einschätzung
            und Entwicklung persönlicher Kompetenzen und Perspektiven über
            Anregungen zur Selbstreflexion bis hin zur Überwindung von
            Konflikten mit Mitarbeitern, Kollegen oder Vorgesetzten. Dabei
            fungiert unser Coach als neutraler, kritischer Gesprächspartner und
            verwendet, je nach Ziel, Methoden aus dem gesamten Spektrum der
            Personal- und Führungskräfteentwicklung.
          </p>
          <p>
            Weitere Informationen und Kontaktaufnahme unter:
            <a href="mailto:mail@pmp-sc.com">mail@pmp-sc.com</a>
          </p>
        </div>
      </div>
    </div>
    <div class="home-topic">
      <h2>Klinik – Restrukturierung</h2>
      <div>
        <div>
          <img
            class="Image"
            src="../assets/home/Arbeitszeitneuorganisation.jpg"
          />
        </div>
        <div>
          <p>
            Restrukturierung oder Reorganisation ist eine grundlegende, über die
            Veränderung der Aufbau- und Ablauforganisation hinausgehende, auch
            betriebswirtschaftliche Umstrukturierung einer Klinik oder eines
            Verbundes. Hierzu haben wir ein Konzept entwickelt um ihre
            Wettbewerbsfähigkeit wieder herzustellen.
          </p>
          <p>
            Weitere Informationen und Kontaktaufnahme unter:
            <a href="mailto:mail@pmp-sc.com">mail@pmp-sc.com</a>
          </p>
        </div>
      </div>
    </div>
    <div class="home-topic">
      <h2>Klinik – Mitarbeiterentwicklung</h2>
      <div>
        <div>
          <img class="Image" src="../assets/home/mitarbeiterentwicklung.jpg" />
        </div>
        <div>
          <p>
            Mitarbeiterentwicklung umfasst die auf die Bedarfe und Bedürfnisse
            einer Klinik abgestimmte arbeitsplatznahe Maßnahmen und Strategien
            aus den Unternehmenszielen zu vermitteln, die eine Qualifizierung
            der Mitarbeiter und -innen zum Ziel haben, um sie zu befähigen, ihre
            Aufgaben im klinischen Alltag erfolgreich und effizient zu
            bewältigen. Gerne begleiten wir sie bei der erfolgreichen Umsetzung.
          </p>
          <p>
            Weitere Informationen und Kontaktaufnahme unter:
            <a href="mailto:mail@pmp-sc.com">mail@pmp-sc.com</a>
          </p>
        </div>
      </div>
    </div>
    <div class="home-topic">
      <h2>Klinik – Outsourcing</h2>
      <div>
        <div>
          <img class="Image" src="../assets/home/outsourcing.jpg" />
        </div>
        <div>
          <p>
            Outsourcing bzw. Auslagerung bezeichnet man die Abgabe von Aufgaben,
            die nicht zum Kerngeschäft einer Klinik gehören. Um den hohen
            Qualitätsanforderungen im Klinikbereich weiterhin gerecht zu werden,
            haben wir ein Konzept entwickelt um ihre Wettbewerbsfähigkeit wieder
            herzustellen.
          </p>
          <p>
            Weitere Informationen und Kontaktaufnahme unter:
            <a href="mailto:mail@pmp-sc.com">mail@pmp-sc.com</a>
          </p>
        </div>
      </div>
    </div>
    <div class="home-topic">
      <h2>Klinik – Arbeitszeit- und Vergütungsmanagement</h2>
      <div>
        <div>
          <img class="Image" src="../assets/home/Outplacement.jpg" />
        </div>
        <div>
          <p>
            Mit der Notwendigkeit zur Flexibilisierung und Individualisierung
            betrieblicher Abläufe hat die Gestaltung der Arbeitszeit und
            Vergütung in den letzten Jahren auch in Kliniken stark an Bedeutung
            gewonnen. Das Arbeitszeitmanagement beschäftigt sich primär mit der
            Erarbeitung und Einführung von Arbeitszeitsystemen aber auch mit der
            Vergütung, die einerseits den betrieblichen Belangen nach
            wechselndem und flexiblem Arbeitszeitbedarf und andererseits den
            individuellen Mitarbeiterinteressen genügen. Gerne begleiten wir sie
            bei der erfolgreichen Umsetzung.
          </p>
          <p>
            Weitere Informationen und Kontaktaufnahme unter:
            <a href="mailto:mail@pmp-sc.com">mail@pmp-sc.com</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {},
})
</script>

<style scoped lang="scss">
.home {
  margin: 0px auto;
  background-color: white;
}
.Image {
  width: 240px;
}
.home-topic > div {
  display: flex;
  margin-bottom: 48px;
}
.home-topic > div div {
  margin-right: 24px;
}
</style>
