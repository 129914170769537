<template>
  <div class="main">
    <TitleBar />
    <Menu />
    <div class="Center">
      <div class="Page">
        <router-view />
      </div>
      <div class="SideBar">
        <!-- <SideBar /> -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TitleBar from './components/TitleBar.vue'
import Menu from './components/Menu.vue'
// import SideBar from './components/SideBar.vue'
import Footer from './components/Footer.vue'

export default defineComponent({
  name: 'App',
  components: {
    TitleBar,
    Menu,
    // SideBar,
    Footer,
  },
  data() {
    return {}
  },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main {
  width: 1200px;
  margin: 0px auto;
  min-height: 100vh;
  background-color: white;
}
.Center {
  display: flex;
  flex-flow: row;
  min-height: 600px;
}
.Page {
  flex: 1;
  padding: 12px 12px 12px 36px;
}
.SideBar {
  flex: 0 0 200px;
  // flex: 0 0 440px;
}
</style>
