<template>
  <div class="HeaderMain">
    <div class="LogoLine">
      <router-link to="/"><div class="Logo"></div> </router-link>
      <div class="Spacer"></div>
      <router-link to="/">
        <div class="backToHome">
          Startseite
        </div>
      </router-link>
      <div class="rightSpacer"></div>
    </div>
    <div class="Banner"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TitleBar',
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.HeaderMain {
}
.LogoLine {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 120px;
}
.Logo {
  background: url('../assets/img/logo.png');
  background-size: 100%;
  background-repeat: no-repeat;
  width: 500px;
  height: 120px;
  cursor: pointer;
}
.Spacer {
  flex: 1;
}
.backToHome {
  display: inline-block;
  width: 132px;
  margin: auto auto;
  border-right: solid 3px #999999;
  border-left: solid 3px #999999;
  text-align: center;
  vertical-align: middle;
  padding: 12px 0px;
}
.rightSpacer {
  flex: 0 0 37px;
}
.Banner {
  background: url('../assets/img/header.png');
  background-size: 100%;
  background-repeat: no-repeat;
  height: 248px;
}
</style>
