<template>
  <div class="MenuMain">
    <div class="SubMenu">
      <div
        class="SubMain"
        v-if="showAbout"
        @mouseleave="() => moveLeave('about')"
      >
        <router-link to="/ueber-uns" class="SubMenuButton">
          Übersicht
        </router-link>
        <router-link to="/ueber-uns/gruender" class="SubMenuButton">
          Gründer
        </router-link>
        <router-link to="/ueber-uns/leitbild" class="SubMenuButton">
          Leitbild
        </router-link>
      </div>
      <router-link
        to="/ueber-uns"
        class="MenuButton"
        @mouseover="() => moveOver('about')"
      >
        Über uns
      </router-link>
    </div>
    <router-link to="/kompetenzen" class="MenuButton">
      Kompetenzen
    </router-link>
    <router-link to="/standort" class="MenuButton">
      Standorte
    </router-link>
    <router-link to="/netzwerk" class="MenuButton">
      Netzwerk
    </router-link>
    <router-link to="/kontakt" class="MenuButton">
      Kontakt
    </router-link>
    <router-link to="/themenfelder" class="MenuButton MenuButtonFixed">
      Themenfelder
    </router-link>
    <router-link to="/umsetzung" class="MenuButton MenuButtonFixed">
      Umsetzung
    </router-link>
    <router-link to="/loesung" class="MenuButton MenuButtonFixed">
      Lösung
    </router-link>
    <div class="rightSpacer"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Menu',
  data() {
    return {
      showAbout: false,
      showCompetency: false,
      showPr: false,
    }
  },
  methods: {
    moveOver(link: 'about' | 'competency' | 'pr') {
      switch (link) {
        case 'about':
          this.showAbout = true
          break
        case 'competency':
          this.showCompetency = true
          break
        case 'pr':
          this.showPr = true
          break
      }
      console.log(link)
    },
    moveLeave(link: 'about' | 'competency' | 'pr') {
      switch (link) {
        case 'about':
          this.showAbout = false
          break
        case 'competency':
          this.showCompetency = false
          break
        case 'pr':
          this.showPr = false
          break
      }
      console.log(link)
    },
  },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.MenuMain {
  border-bottom: solid 2px #1c4893;
  display: flex;
  width: 100%;
}
.MenuButton {
  flex: 1;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
  padding: 10px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 13%,
    rgba(242, 242, 242, 1) 44%,
    rgba(237, 237, 237, 1) 71%,
    rgba(230, 230, 230, 1) 100%
  );
  border-right: solid 2px #e5e2dd;
  transition: background 2s;
}
.MenuButton:hover {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 13%,
    rgba(242, 242, 242, 1) 44%,
    rgba(237, 237, 237, 1) 71%,
    rgba(230, 230, 230, 1) 100%
  );
}
.MenuButtonFixed {
  flex: 0 0 113px;
}
.rightSpacer {
  flex: 0 0 32px;
}
.SubMenu {
  position: relative;
}
.SubMain {
  position: absolute;
  left: 0px;
  top: 0px;
  padding-top: 39px;
  & > a {
    width: 100%;
  }
}
.SubMenuButton {
  flex: 1;
  white-space: nowrap;
  text-align: left;
  display: inline-block;
  padding: 10px;
  background-color: rgba(242, 242, 242, 1);
  border-right: solid 2px #e5e2dd;
  transition: background-color 500ms;
}
.SubMenuButton:hover {
  background-color: rgb(255, 255, 255);
}
</style>
