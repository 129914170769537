
import { defineComponent } from 'vue'
import TitleBar from './components/TitleBar.vue'
import Menu from './components/Menu.vue'
// import SideBar from './components/SideBar.vue'
import Footer from './components/Footer.vue'

export default defineComponent({
  name: 'App',
  components: {
    TitleBar,
    Menu,
    // SideBar,
    Footer,
  },
  data() {
    return {}
  },
})
