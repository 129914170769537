<template>
  <div class="FooterMain">
    © 2021 peter milde + partner /
    <router-link to="/impressum">
      Impressum
    </router-link>
    /
    <router-link to="/dsgvo">
      Datenschutzerklärung
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Footer',
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.FooterMain {
  border-top: solid 2px #1c4893;
}
</style>
