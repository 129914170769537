import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/ueber-uns',
    name: 'Über uns',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/about/About.vue'),
  },
  {
    path: '/ueber-uns/gruender',
    name: 'Gründer',
    component: () =>
      import(/* webpackChunkName: "gruender" */ '../views/about/Gruender.vue'),
  },
  {
    path: '/ueber-uns/leitbild',
    name: 'Leitbild',
    component: () =>
      import(/* webpackChunkName: "leitbild" */ '../views/about/Leitbild.vue'),
  },
  {
    path: '/kompetenzen',
    name: 'Übersicht',
    component: () =>
      import(
        /* webpackChunkName: "kompetenzen" */ '../views/competence/Kompetenzen.vue'
      ),
  },
  {
    path: '/standort',
    name: 'Standort',
    component: () =>
      import(/* webpackChunkName: "standort" */ '../views/Standort.vue'),
  },
  {
    path: '/netzwerk',
    name: 'Netwerk',
    component: () =>
      import(/* webpackChunkName: "netzwerk" */ '../views/Netzwerk.vue'),
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () =>
      import(/* webpackChunkName: "kontakt" */ '../views/Kontakt.vue'),
  },
  {
    path: '/themenfelder',
    name: 'Themenfelder',
    component: () =>
      import(
        /* webpackChunkName: "themenfelder" */ '../views/Themenfelder.vue'
      ),
  },
  {
    path: '/umsetzung',
    name: 'Umsetzung',
    component: () =>
      import(/* webpackChunkName: "umsetzung" */ '../views/Umsetzung.vue'),
  },
  {
    path: '/loesung',
    name: 'Lösung',
    component: () =>
      import(/* webpackChunkName: "loesung" */ '../views/Lösung.vue'),
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () =>
      import(/* webpackChunkName: "impressum" */ '../views/Impressum.vue'),
  },
  {
    path: '/dsgvo',
    name: 'DSGVO',
    component: () =>
      import(/* webpackChunkName: "dsgvo" */ '../views/DSGVO.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
